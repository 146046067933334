import React from "react"
import MainLayout from "../../layouts"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ContactHeading from "../../components/ContactHeading"
import ServiceCards from "../../ServiceCards"

export const pageQuery = graphql`
  query {
    lincolnImage: file(relativePath: { eq: "lincoln.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithCarInNeighborhoodImage: file(
      relativePath: { eq: "locksmith-car-in-neighborhood.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoWithRekeySetImage: file(relativePath: { eq: "leo-with-rekey-set.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function LincolnPage({ data }) {
  return (
    <MainLayout
      title="Best Local Locksmith in Lincoln, CA | 916-995-0135 | 24 Hour"
      description="Swift Locksmith offers 24/7 service in Lincoln, CA with a full team of trustworthy, professional locksmiths who provide car, home & business locksmith services."
      offset
    >
      <ContactHeading background={data.lincolnImage.childImageSharp.fluid}>
        <h1 className="text-center">Locksmith Lincoln</h1>
        <h2 className="text-center font-weight-light">
          24 Hour Locksmith serving Lincoln, CA
        </h2>
      </ContactHeading>
      <section>
        <ServiceCards />
      </section>
      <section>
        <div className="background-content bg-dark text-white pb-0 pb-sm-5 pb-md-6">
          <Container>
            <h2 className="text-center text-primary">
              Full Service Locksmith in Lincoln, CA
            </h2>
            <Row>
              <Col xs={12} md={{ span: 6, order: 2 }} lg={7}>
                <p className="indent">
                  Swift Locksmith is your local locksmith company providing the
                  highest quality residential, commercial, and automotive
                  locksmith services for Lincoln, CA and the surrounding areas.
                  We genuinely care for the safety and security of our customers
                  and offer 24/7 customer service to assist you with any
                  locksmith need that you may have, at any given time.
                </p>
                <p className="indent">
                  Our team of skilled professionals have been working in the
                  locksmith industry for years and have gained experience and
                  knowledge about everything related to locksmith services, so
                  you can rest assured that you are in good hands with us. Swift
                  locksmith technicians are all licensed, friendly, and
                  professional.
                </p>
                <p className="indent mb-md-0">
                  We always work in an organized, productive manner to make sure
                  that your locksmith issues are resolved carefully and easily
                  without damaging any of your things or property. We are
                  committed to providing you with exceptional customer service,
                  quality materials and competitive pricing.
                </p>
              </Col>
              <Col
                xs={12}
                md={{ span: 6, order: 1 }}
                lg={5}
                className="col-sm-pad"
              >
                <Img
                  fluid={
                    data.locksmithCarInNeighborhoodImage.childImageSharp.fluid
                  }
                  alt="locksmith-car-in-neighborhood"
                  style={{ maxHeight: 500 }}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Container as="section">
        <h2>Our Services</h2>
        <h3 className="mb-2">Residential Locksmith Services</h3>
        <Row className="mb-3">
          <Col xs={12} md={6} lg={5} xl={4}>
            <ul className="mb-0">
              <li className="lead">24/7 Emergency Lockout</li>
              <li className="lead">Door Knob Lock Installation</li>
              <li className="lead">Whole House Rekey</li>
              <li className="lead">Lock Repair & Maintenance</li>
            </ul>
          </Col>
          <Col xs={12} md={6} lg={7} xl={8}>
            <ul className="mb-0">
              <li className="lead">High Security Locks</li>
              <li className="lead">Duplicate House Keys</li>
              <li className="lead">On-Site Key Extraction</li>
              <li className="lead">Master Key Systems</li>
            </ul>
          </Col>
        </Row>
        <h3 className="mb-2">Automotive Locksmith Services</h3>
        <Row className="mb-3">
          <Col xs={12} md={6} lg={5} xl={4}>
            <ul className="mb-0">
              <li className="lead">24/7 Emergency Lockout</li>
              <li className="lead">Broken Key Extraction</li>
              <li className="lead">Ignition Rekey</li>
              <li className="lead">Laser Cut Keys</li>
            </ul>
          </Col>
          <Col xs={12} md={6} lg={7} xl={8}>
            <ul className="mb-0">
              <li className="lead">Chip Keys Cut Services</li>
              <li className="lead">Remote Programming</li>
              <li className="lead">Lost Car Key Replacement</li>
              <li className="lead">Replace Keyless Entry Remotes</li>
            </ul>
          </Col>
        </Row>
        <h3 className="mb-2">Commercial Locksmith Services</h3>
        <Row className="mb-3">
          <Col xs={12} md={6} lg={5} xl={4}>
            <ul className="mb-0">
              <li className="lead">24/7 Emergency Lockout</li>
              <li className="lead">Business Rekey</li>
              <li className="lead">Locks & Exit Device Repairs</li>
              <li className="lead">Master Key System Installation</li>
            </ul>
          </Col>
          <Col xs={12} md={6} lg={7} xl={8}>
            <ul className="mb-0">
              <li className="lead">Keyless Access System</li>
              <li className="lead">
                Aluminum Door Lock Alignment & Installation
              </li>
              <li className="lead">On-site Key Duplication</li>
              <li className="lead">High Security & Standard Deadbolts</li>
            </ul>
          </Col>
        </Row>
        <h3 className="mb-2">Emergency Locksmith Services</h3>
        <Row>
          <Col xs={12} md={6} lg={5} xl={4}>
            <ul className="mb-0">
              <li className="lead">Emergency Rekey Services</li>
              <li className="lead">Car,House, Business Lockouts</li>
              <li className="lead">Emergency Lock Repair Services</li>
              <li className="lead">Bedroom,Storage, Garage Lockouts</li>
            </ul>
          </Col>
          <Col xs={12} md={6} lg={7} xl={8}>
            <ul className="mb-0">
              <li className="lead">Mail Box Lockouts</li>
              <li className="lead">Replacement of Lost Car Keys</li>
              <li className="lead">Broken Key Extraction</li>
              <li className="lead">Duplicate Keys</li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container as="section" className="pb-0 pb-sm-5 mb-md-5">
        <h2>Why Choose Swift Locksmith in Lincoln, CA</h2>
        <Row>
          <Col xs={12} md={6}>
            <p className="indent">
              Your safety becomes tour priority whenever you call Swift
              Locksmith. Whether you need a duplicate business key, locked out
              of your vehicle, or lost your house keys, you can rely on our
              expert technicians to get the job done.
            </p>
            <p className="indent">
              You won't ever need to doubt the quality of service you are going
              to receive since we ensure every one of our jobs and supply all
              costs for you in advance which means you will not find additional
              charges or hidden costs after a task is complete.
            </p>
          </Col>
          <Col xs={12} md={6} className="col-sm-pad">
            <Img
              fluid={data.leoWithRekeySetImage.childImageSharp.fluid}
              alt="leo-with-rekey-set"
            />
          </Col>
        </Row>
      </Container>
    </MainLayout>
  )
}

export default LincolnPage
